import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Route, Link, Routes, BrowserRouter} from "react-router-dom"
import Navbar from "./Navbar"
import Home from "./pages/Home"
import About from "./pages/About"
import Conatct from './pages/Contact';
import Services from './pages/Services';
import Projects from './pages/Projects';
import Portfolio from './pages/Portfolio';
import Footer from './pages/Footer';
import PageNotFound from "./pages/PageNotFound";
function App() {
  return (
    <>
      <BrowserRouter>
      <Navbar />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Conatct />} />
            <Route path="/services" element={<Services />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  )
}

export default App;
