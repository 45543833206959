import { Link, BrowserRouter as Router } from "react-router-dom"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
export default function Home() {
    const unSlider ={
        loop: true,
        margin: 0,
        items: 1,
        dots: false,
        nav: true,
        navText: ['<span class="arrow_carrot-left"></span>', '<span class="arrow_carrot-right"></span>'],
        smartSpeed: 1200,
        dotsEach: 2,
        autoplay: true
      };
      const vgSlider ={
        loop: true,
        margin: 0,
        items: 1,
        dots: false,
        nav: true,
        navText: ['<span class="arrow_carrot-left"></span>', '<span class="arrow_carrot-right"></span>'],
        smartSpeed: 1200,
        autoplay: true
      };
      const trendingSlider ={
        loop: true,
        margin: 0,
        items: 1,
        dots: false,
        nav: true,
        navText: ['<span class="arrow_carrot-left"></span>', '<span class="arrow_carrot-right"></span>'],
        dotsEach: 2,
        smartSpeed: 1200,
        autoplay: true
      };
      const lpSlider ={
        loop: true,
        margin: 0,
        items: 4,
        dots: true,
        nav: true,
        navText: ['<span class="arrow_carrot-left"></span>', '<span class="arrow_carrot-right"></span>'],
        smartSpeed: 1200,
        dotsEach: 2,
        autoplay: true,
        responsive: {
            320: {
                items: 1
            },
            480: {
                items: 2
            },
            768: {
                items: 3
            },
            992: {
                items: 4
            }
        }
      };
    return(
        <div className="App">
            <>
            
            {/* Hero Section Begin */}
            <section className="hero-section">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                    <div className="hs-text">
                        <div className="label">
                        <span>Playstation</span>
                        </div>
                        <h3>
                        Downwell and Space Hulk: Tactics are coming to Xbox Game Pass for
                        PC
                        </h3>
                        <div className="ht-meta">
                        <img src="img/hero/meta-pic.jpg" alt="" />
                        <ul>
                            <li>by Marcin Jankowski</li>
                            <li>Aug 01, 2019</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-6 offset-lg-1 offset-xl-2">
                    <div className="trending-post">
                        <div className="section-title">
                        <h5>Treding posts</h5>
                        </div>
                        <OwlCarousel className=" trending-slider owl-carousel" {...trendingSlider}>
                        <div className="single-trending-item item">
                            <div className="trending-item">
                            <div className="ti-pic">
                                <img src="img/trending/trending-1.jpg" alt="" />
                            </div>
                            <div className="ti-text">
                                <h6>
                                <a href="/#">
                                    1. Jalopy developer is making a game where you 'build
                                    stuff...
                                </a>
                                </h6>
                                <ul>
                                <li>
                                    <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                    <i className="fa fa-comment-o" /> 12
                                </li>
                                </ul>
                            </div>
                            </div>
                            <div className="trending-item">
                            <div className="ti-pic">
                                <img src="img/trending/trending-2.jpg" alt="" />
                            </div>
                            <div className="ti-text">
                                <h6>
                                <a href="/#">
                                    2. Valve updates Steam's new Interactive Reco- mmender,
                                    teases a...
                                </a>
                                </h6>
                                <ul>
                                <li>
                                    <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                    <i className="fa fa-comment-o" /> 12
                                </li>
                                </ul>
                            </div>
                            </div>
                            <div className="trending-item">
                            <div className="ti-pic">
                                <img src="img/trending/trending-3.jpg" alt="" />
                            </div>
                            <div className="ti-text">
                                <h6>
                                <a href="/#">
                                    3. Jalopy developer is making a game where you 'build
                                    stuff...
                                </a>
                                </h6>
                                <ul>
                                <li>
                                    <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                    <i className="fa fa-comment-o" /> 12
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="single-trending-item item">
                            <div className="trending-item">
                            <div className="ti-pic">
                                <img src="img/trending/trending-5.jpg" alt="" />
                            </div>
                            <div className="ti-text">
                                <h6>
                                <a href="/#">
                                    4. Jalopy developer is making a game where you 'build
                                    stuff...
                                </a>
                                </h6>
                                <ul>
                                <li>
                                    <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                    <i className="fa fa-comment-o" /> 12
                                </li>
                                </ul>
                            </div>
                            </div>
                            <div className="trending-item">
                            <div className="ti-pic">
                                <img src="img/trending/trending-6.jpg" alt="" />
                            </div>
                            <div className="ti-text">
                                <h6>
                                <a href="/#">
                                    5. Valve updates Steam's new Interactive Reco- mmender,
                                    teases a...
                                </a>
                                </h6>
                                <ul>
                                <li>
                                    <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                    <i className="fa fa-comment-o" /> 12
                                </li>
                                </ul>
                            </div>
                            </div>
                            <div className="trending-item">
                            <div className="ti-pic">
                                <img src="img/trending/trending-7.jpg" alt="" />
                            </div>
                            <div className="ti-text">
                                <h6>
                                <a href="/#">
                                    Jalopy developer is making a game where you 'build
                                    stuff...
                                </a>
                                </h6>
                                <ul>
                                <li>
                                    <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                    <i className="fa fa-comment-o" /> 12
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </OwlCarousel>
                    </div>
                    </div>
                </div>
                </div>
                <div className="hero-slider">
                {/* <div class="hs-item set-bg" data-setbg="img/hero/hero-1.jpg"></div> */}
                {/* <div class="hs-item" data-merge="1"><a class="owl-video" href="https://www.youtube.com/watch?v=JpxsRwnRwCQ"></a></div> */}
                {/* <div class="hs-item set-bg" data-setbg="img/hero/hero-2.jpg"></div>
                <div class="hs-item set-bg" data-setbg="img/hero/hero-3.jpg"></div> */}
                <video autoPlay={true} loop={true} muted={true} className="back-video" style={{ width: "100%" }} >
                    <source src="videos/home/6.mp4" type="video/mp4" />
                </video>
                </div>
            </section>
            {/* Hero Section End */}
            {/* Latest Preview Section Begin */}
            <section className="latest-preview-section">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="section-title">
                        <h5>Latest Preview</h5>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <OwlCarousel className="lp-slider owl-carousel" {...lpSlider}>
                        <div className="col-lg-3">
                            <div className="lp-item">
                            <div
                                className="lp-pic set-bg"
                                data-setbg="img/latest-preview/lp-1.jpg"
                            >
                                <div className="review-loader">
                                <div className="loader-circle-wrap">
                                    <div className="loader-circle">
                                    <span
                                        className="circle-progress"
                                        data-cpid="id"
                                        data-cpvalue={75}
                                        data-cpcolor="#4663E3"
                                    />
                                    <div className="review-point">7.5</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="lp-text">
                                <h6>
                                <a href="#">Teamfight Tactics is in chaos after patch...</a>
                                </h6>
                                <ul>
                                <li>
                                    <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                    <i className="fa fa-comment-o" /> 12
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="lp-item">
                            <div
                                className="lp-pic set-bg"
                                data-setbg="img/latest-preview/lp-2.jpg"
                            >
                                <div className="review-loader">
                                <div className="loader-circle-wrap">
                                    <div className="loader-circle">
                                    <span
                                        className="circle-progress"
                                        data-cpid="id"
                                        data-cpvalue={75}
                                        data-cpcolor="#4663E3"
                                    />
                                    <div className="review-point">7.5</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="lp-text">
                                <h6>
                                <a href="#">
                                    Lenovo’s y540 gaming laptop with an rtx 2060...
                                </a>
                                </h6>
                                <ul>
                                <li>
                                    <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                    <i className="fa fa-comment-o" /> 12
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="lp-item">
                            <div
                                className="lp-pic set-bg"
                                data-setbg="img/latest-preview/lp-3.jpg"
                            >
                                <div className="review-loader">
                                <div className="loader-circle-wrap">
                                    <div className="loader-circle">
                                    <span
                                        className="circle-progress"
                                        data-cpid="id"
                                        data-cpvalue={75}
                                        data-cpcolor="#4663E3"
                                    />
                                    <div className="review-point">7.5</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="lp-text">
                                <h6>
                                <a href="#">A monter prom poster got hijacked for a...</a>
                                </h6>
                                <ul>
                                <li>
                                    <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                    <i className="fa fa-comment-o" /> 12
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="lp-item">
                            <div
                                className="lp-pic set-bg"
                                data-setbg="img/latest-preview/lp-4.jpg"
                            >
                                <div className="review-loader">
                                <div className="loader-circle-wrap">
                                    <div className="loader-circle">
                                    <span
                                        className="circle-progress"
                                        data-cpid="id"
                                        data-cpvalue={75}
                                        data-cpcolor="#4663E3"
                                    />
                                    <div className="review-point">7.5</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="lp-text">
                                <h6>
                                <a href="#">Facebook wants to read your thoughts with...</a>
                                </h6>
                                <ul>
                                <li>
                                    <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                    <i className="fa fa-comment-o" /> 12
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="lp-item">
                            <div
                                className="lp-pic set-bg"
                                data-setbg="img/latest-preview/lp-4.jpg"
                            >
                                <div className="review-loader">
                                <div className="loader-circle-wrap">
                                    <div className="loader-circle">
                                    <span
                                        className="circle-progress"
                                        data-cpid="id"
                                        data-cpvalue={75}
                                        data-cpcolor="#4663E3"
                                    />
                                    <div className="review-point">7.5</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="lp-text">
                                <h6>
                                <a href="#">Facebook wants to read your thoughts with...</a>
                                </h6>
                                <ul>
                                <li>
                                    <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                    <i className="fa fa-comment-o" /> 12
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="lp-item">
                            <div
                                className="lp-pic set-bg"
                                data-setbg="img/latest-preview/lp-4.jpg"
                            >
                                <div className="review-loader">
                                <div className="loader-circle-wrap">
                                    <div className="loader-circle">
                                    <span
                                        className="circle-progress"
                                        data-cpid="id"
                                        data-cpvalue={75}
                                        data-cpcolor="#4663E3"
                                    />
                                    <div className="review-point">7.5</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="lp-text">
                                <h6>
                                <a href="#">Facebook wants to read your thoughts with...</a>
                                </h6>
                                <ul>
                                <li>
                                    <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                    <i className="fa fa-comment-o" /> 12
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="lp-item">
                            <div
                                className="lp-pic set-bg"
                                data-setbg="img/latest-preview/lp-4.jpg"
                            >
                                <div className="review-loader">
                                <div className="loader-circle-wrap">
                                    <div className="loader-circle">
                                    <span
                                        className="circle-progress"
                                        data-cpid="id"
                                        data-cpvalue={75}
                                        data-cpcolor="#4663E3"
                                    />
                                    <div className="review-point">7.5</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="lp-text">
                                <h6>
                                <a href="#">Facebook wants to read your thoughts with...</a>
                                </h6>
                                <ul>
                                <li>
                                    <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                    <i className="fa fa-comment-o" /> 12
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
                </div>
            </section>
            {/* Latest Preview Section End */}
            {/* Update News Section Begin */}
            <section className="update-news-section">
                <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                    <div className="section-title">
                        <h5>
                        <span>News &amp; update</span>
                        </h5>
                    </div>
                    <div className="tab-elem">
                        <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                            <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#tabs-1"
                            role="tab"
                            >
                            All
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-2"
                            role="tab"
                            >
                            Platform
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-3"
                            role="tab"
                            >
                            Hardware
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabs-4"
                            role="tab"
                            >
                            Reviews
                            </a>
                        </li>
                        </ul>
                        {/* Tab panes */}
                        <div className="tab-content">
                        <div
                            className="tab-pane fade show active"
                            id="tabs-1"
                            role="tabpanel"
                        >
                            <div className="row">
                            <OwlCarousel className="un-slider owl-carousel" {...unSlider}>
                                <div className="col-lg-12">
                                <div
                                    className="un-big-item set-bg item"
                                    data-setbg="img/news/news-1.jpg"
                                >
                                    <div className="ub-text">
                                    <div className="label">
                                        <span>Reviews</span>
                                    </div>
                                    <h5>
                                        <a href="/#">
                                        Get one of our favorite nvme ssds, the 2tb samsung
                                        970 evo, for $120 less than nomal
                                        </a>
                                    </h5>
                                    <ul>
                                        <li>
                                        by <span>Admin</span>
                                        </li>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 20
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-3.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-4.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-2.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-12">
                                <div
                                    className="un-big-item set-bg item"
                                    data-setbg="img/news/news-1.jpg"
                                >
                                    <div className="ub-text">
                                    <div className="label">
                                        <span>Reviews</span>
                                    </div>
                                    <h5>
                                        <a href="/#">
                                        Get one of our favorite nvme ssds, the 2tb samsung
                                        970 evo, for $120 less than nomal
                                        </a>
                                    </h5>
                                    <ul>
                                        <li>
                                        by <span>Admin</span>
                                        </li>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 20
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-3.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-4.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-2.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </OwlCarousel>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="tabs-2" role="tabpanel">
                            <div className="row">
                            <div className="un-slider owl-carousel">
                                <div className="col-lg-12">
                                <div
                                    className="un-big-item set-bg"
                                    data-setbg="img/news/news-1.jpg"
                                >
                                    <div className="ub-text">
                                    <div className="label">
                                        <span>Reviews</span>
                                    </div>
                                    <h5>
                                        <a href="/#">
                                        Get one of our favorite nvme ssds, the 2tb samsung
                                        970 evo, for $120 less than nomal
                                        </a>
                                    </h5>
                                    <ul>
                                        <li>
                                        by <span>Admin</span>
                                        </li>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 20
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-3.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-4.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-2.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-12">
                                <div
                                    className="un-big-item set-bg"
                                    data-setbg="img/news/news-1.jpg"
                                >
                                    <div className="ub-text">
                                    <div className="label">
                                        <span>Reviews</span>
                                    </div>
                                    <h5>
                                        <a href="/#">
                                        Get one of our favorite nvme ssds, the 2tb samsung
                                        970 evo, for $120 less than nomal
                                        </a>
                                    </h5>
                                    <ul>
                                        <li>
                                        by <span>Admin</span>
                                        </li>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 20
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-3.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-4.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-2.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="tabs-3" role="tabpanel">
                            <div className="row">
                            <div className="un-slider owl-carousel">
                                <div className="col-lg-12">
                                <div
                                    className="un-big-item set-bg"
                                    data-setbg="img/news/news-1.jpg"
                                >
                                    <div className="ub-text">
                                    <div className="label">
                                        <span>Reviews</span>
                                    </div>
                                    <h5>
                                        <a href="/#">
                                        Get one of our favorite nvme ssds, the 2tb samsung
                                        970 evo, for $120 less than nomal
                                        </a>
                                    </h5>
                                    <ul>
                                        <li>
                                        by <span>Admin</span>
                                        </li>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 20
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-3.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-4.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-2.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-12">
                                <div
                                    className="un-big-item set-bg"
                                    data-setbg="img/news/news-1.jpg"
                                >
                                    <div className="ub-text">
                                    <div className="label">
                                        <span>Reviews</span>
                                    </div>
                                    <h5>
                                        <a href="/#">
                                        Get one of our favorite nvme ssds, the 2tb samsung
                                        970 evo, for $120 less than nomal
                                        </a>
                                    </h5>
                                    <ul>
                                        <li>
                                        by <span>Admin</span>
                                        </li>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 20
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-3.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-4.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-2.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="tabs-4" role="tabpanel">
                            <div className="row">
                            <div className="un-slider owl-carousel">
                                <div className="col-lg-12">
                                <div
                                    className="un-big-item set-bg"
                                    data-setbg="img/news/news-1.jpg"
                                >
                                    <div className="ub-text">
                                    <div className="label">
                                        <span>Reviews</span>
                                    </div>
                                    <h5>
                                        <a href="/#">
                                        Get one of our favorite nvme ssds, the 2tb samsung
                                        970 evo, for $120 less than nomal
                                        </a>
                                    </h5>
                                    <ul>
                                        <li>
                                        by <span>Admin</span>
                                        </li>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 20
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-3.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-4.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-2.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-12">
                                <div
                                    className="un-big-item set-bg"
                                    data-setbg="img/news/news-1.jpg"
                                >
                                    <div className="ub-text">
                                    <div className="label">
                                        <span>Reviews</span>
                                    </div>
                                    <h5>
                                        <a href="/#">
                                        Get one of our favorite nvme ssds, the 2tb samsung
                                        970 evo, for $120 less than nomal
                                        </a>
                                    </h5>
                                    <ul>
                                        <li>
                                        by <span>Admin</span>
                                        </li>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 20
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-3.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-4.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-md-4">
                                    <div className="un-item">
                                        <div
                                        className="un_pic set-bg"
                                        data-setbg="img/news/news-2.jpg"
                                        >
                                        <div className="label">
                                            <span>Reviews</span>
                                        </div>
                                        </div>
                                        <div className="un_text">
                                        <h6>
                                            <a href="/#">
                                            Downwell and space hulk: tactics are coming
                                            to...
                                            </a>
                                        </h6>
                                        <ul>
                                            <li>
                                            <i className="fa fa-clock-o" /> Aug 01, 2019
                                            </li>
                                            <li>
                                            <i className="fa fa-comment-o" /> 20
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="sidebar-option">
                        <div className="social-media">
                        <div className="section-title">
                            <h5>Social media</h5>
                        </div>
                        <ul>
                            <li>
                            <div className="sm-icon">
                                <i className="fa fa-facebook" />
                            </div>
                            <span>Facebook</span>
                            <div className="follow">1,2k Follow</div>
                            </li>
                            <li>
                            <div className="sm-icon">
                                <i className="fa fa-twitter" />
                            </div>
                            <span>Twitter</span>
                            <div className="follow">1,2k Follow</div>
                            </li>
                            <li>
                            <div className="sm-icon">
                                <i className="fa fa-youtube-play" />
                            </div>
                            <span>Youtube</span>
                            <div className="follow">2,3k Subs</div>
                            </li>
                            <li>
                            <div className="sm-icon">
                                <i className="fa fa-instagram" />
                            </div>
                            <span>Instagram</span>
                            <div className="follow">2,6k Follow</div>
                            </li>
                        </ul>
                        </div>
                        <div className="hardware-guides">
                        <div className="section-title">
                            <h5>Hardware guides</h5>
                        </div>
                        <div className="trending-item">
                            <div className="ti-pic">
                            <img src="img/trending/trending-5.jpg" alt="" />
                            </div>
                            <div className="ti-text">
                            <h6>
                                <a href="/#">
                                A Monster Prom poster got hijacked for a Papa Roach
                                concert...
                                </a>
                            </h6>
                            <ul>
                                <li>
                                <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                <i className="fa fa-comment-o" /> 12
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div className="trending-item">
                            <div className="ti-pic">
                            <img src="img/trending/trending-6.jpg" alt="" />
                            </div>
                            <div className="ti-text">
                            <h6>
                                <a href="/#">
                                Facebook wants to read your thoughts with its augmented...
                                </a>
                            </h6>
                            <ul>
                                <li>
                                <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                <i className="fa fa-comment-o" /> 12
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div className="trending-item">
                            <div className="ti-pic">
                            <img src="img/trending/trending-7.jpg" alt="" />
                            </div>
                            <div className="ti-text">
                            <h6>
                                <a href="/#">
                                This gaming laptop with a GTX 1660 Ti and 32GB of RAM is
                                down...
                                </a>
                            </h6>
                            <ul>
                                <li>
                                <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                <i className="fa fa-comment-o" /> 12
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div className="trending-item">
                            <div className="ti-pic">
                            <img src="img/trending/trending-8.jpg" alt="" />
                            </div>
                            <div className="ti-text">
                            <h6>
                                <a href="/#">
                                Jalopy developer is making a game where you 'build
                                stuff...
                                </a>
                            </h6>
                            <ul>
                                <li>
                                <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                <i className="fa fa-comment-o" /> 12
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            {/* Update News Section End */}
            {/* Videos Guide Section Begin */}
            <section className="video-guide-section">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="section-title">
                        <h5>Videos guide</h5>
                    </div>
                    </div>
                </div>
                
                <div className="tab-elem">
                    <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                        <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#tabs-5"
                        role="tab"
                        >
                        All
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#tabs-6" role="tab">
                        Platform
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#tabs-7" role="tab">
                        Hardware
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#tabs-8" role="tab">
                        Reviews
                        </a>
                    </li>
                    </ul>
                    {/* Tab panes */}
                    <div className="tab-content">
                    <div
                        className="tab-pane fade show active"
                        id="tabs-5"
                        role="tabpanel"
                    >
                        <div className="row">
                        <OwlCarousel className="vg-slider owl-carousel" {...vgSlider}>
                            <div className="col-lg-12 item">
                            <div className="row">
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-1.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-2.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-3.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-4.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-5.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-12 item">
                            <div className="row">
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-1.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-2.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-3.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-4.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-5.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </OwlCarousel>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="tabs-6" role="tabpanel">
                        <div className="row">
                        <div className="vg-slider owl-carousel">
                            <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-1.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-2.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-3.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-4.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-5.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-1.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-2.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-3.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-4.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-5.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="tabs-7" role="tabpanel">
                        <div className="row">
                        <div className="vg-slider owl-carousel">
                            <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-1.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-2.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-3.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-4.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-5.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-1.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-2.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-3.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-4.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-5.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="tabs-8" role="tabpanel">
                        <div className="row">
                        <div className="vg-slider owl-carousel">
                            <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-1.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-2.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-3.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-4.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-5.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-1.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div
                                    className="vg-item large-vg set-bg"
                                    data-setbg="img/videos/videos-2.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h5>
                                        THROUGH THE AGES' FIRST EXPANSION WILL BE RELEASING
                                        ON DIGITAL PLATFORMS...
                                    </h5>
                                    <ul>
                                        <li>
                                        <i className="fa fa-clock-o" /> Aug 01, 2019
                                        </li>
                                        <li>
                                        <i className="fa fa-comment-o" /> 12
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-3.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-4.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div
                                    className="vg-item set-bg"
                                    data-setbg="img/videos/videos-5.jpg"
                                >
                                    <a
                                    href="https://www.youtube.com/watch?v=EzKkl64rRbM"
                                    className="play-btn video-popup"
                                    >
                                    <i className="fa fa-play" />
                                    </a>
                                    <div className="vg-text">
                                    <h6>
                                        THis gam,ing laptop with Gtx 1660 ti and of ram is
                                    </h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            {/* Videos Guide Section End */}
            {/* Instagram Post Section Begin */}
            <section className="instagram-post-section spad">
                <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                    <div className="section-title">
                        <h5>Instagram</h5>
                    </div>
                    <div className="ip-item">
                        <div className="ip-pic">
                        <img src="img/instagram/insta-1.jpg" alt="" />
                        </div>
                        <div className="ip-text">
                        <div className="label">
                            <span>Reviews</span>
                        </div>
                        <h5>
                            <a href="/#">
                            Here's an hour of side-quests from The Outer Worlds...
                            </a>
                        </h5>
                        <ul>
                            <li>
                            by <span>Admin</span>
                            </li>
                            <li>
                            <i className="fa fa-clock-o" /> Aug 01, 2019
                            </li>
                            <li>
                            <i className="fa fa-comment-o" /> 20
                            </li>
                        </ul>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                            ipsum suspendisse ultrices...
                        </p>
                        </div>
                    </div>
                    <div className="ip-item">
                        <div className="ip-pic">
                        <img src="img/instagram/insta-2.jpg" alt="" />
                        </div>
                        <div className="ip-text">
                        <div className="label">
                            <span>Windows</span>
                        </div>
                        <h5>
                            <a href="/#">
                            Infinity Ward's focus isn't on battle royale, Call of...
                            </a>
                        </h5>
                        <ul>
                            <li>
                            by <span>Admin</span>
                            </li>
                            <li>
                            <i className="fa fa-clock-o" /> Aug 01, 2019
                            </li>
                            <li>
                            <i className="fa fa-comment-o" /> 20
                            </li>
                        </ul>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                            ipsum suspendisse ultrices...
                        </p>
                        </div>
                    </div>
                    <div className="ip-item">
                        <div className="ip-pic">
                        <img src="img/instagram/insta-3.jpg" alt="" />
                        </div>
                        <div className="ip-text">
                        <div className="label">
                            <span>Hardware</span>
                        </div>
                        <h5>
                            <a href="/#">
                            Capcom asks select fans to test new Resident Evil game
                            </a>
                        </h5>
                        <ul>
                            <li>
                            by <span>Admin</span>
                            </li>
                            <li>
                            <i className="fa fa-clock-o" /> Aug 01, 2019
                            </li>
                            <li>
                            <i className="fa fa-comment-o" /> 20
                            </li>
                        </ul>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                            ipsum suspendisse ultrices...
                        </p>
                        </div>
                    </div>
                    <div className="ip-item">
                        <div className="ip-pic">
                        <img src="img/instagram/insta-4.jpg" alt="" />
                        </div>
                        <div className="ip-text">
                        <div className="label">
                            <span>Playstation</span>
                        </div>
                        <h5>
                            <a href="/#">
                            Teamfight Tactics is in chaos after today's patch...
                            </a>
                        </h5>
                        <ul>
                            <li>
                            by <span>Admin</span>
                            </li>
                            <li>
                            <i className="fa fa-clock-o" /> Aug 01, 2019
                            </li>
                            <li>
                            <i className="fa fa-comment-o" /> 20
                            </li>
                        </ul>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                            ipsum suspendisse ultrices...
                        </p>
                        </div>
                    </div>
                    <div className="pagination-item">
                        <a href="/#">
                        <span>1</span>
                        </a>
                        <a href="/#">
                        <span>2</span>
                        </a>
                        <a href="/#">
                        <span>3</span>
                        </a>
                        <a href="/#">
                        <span>Next</span>
                        </a>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-7">
                    <div className="sidebar-option">
                        <div className="insta-media">
                        <div className="section-title">
                            <h5>Instagram</h5>
                        </div>
                        <div className="insta-pic">
                            <img src="img/instagram/ip-1.jpg" alt="" />
                            <img src="img/instagram/ip-2.jpg" alt="" />
                            <img src="img/instagram/ip-3.jpg" alt="" />
                            <img src="img/instagram/ip-4.jpg" alt="" />
                        </div>
                        </div>
                        <div className="best-of-post">
                        <div className="section-title">
                            <h5>Best of</h5>
                        </div>
                        <div className="bp-item">
                            <div className="bp-loader">
                            <div className="loader-circle-wrap">
                                <div className="loader-circle">
                                <span
                                    className="circle-progress-1"
                                    data-cpid="id-1"
                                    data-cpvalue={95}
                                    data-cpcolor="#4663E3"
                                />
                                <div className="review-point">9.5</div>
                                </div>
                            </div>
                            </div>
                            <div className="bp-text">
                            <h6>
                                <a href="/#">This gaming laptop with a GTX 1660...</a>
                            </h6>
                            <ul>
                                <li>
                                <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                <i className="fa fa-comment-o" /> 20
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div className="bp-item">
                            <div className="bp-loader">
                            <div className="loader-circle-wrap">
                                <div className="loader-circle">
                                <span
                                    className="circle-progress-1"
                                    data-cpid="id-2"
                                    data-cpvalue={85}
                                    data-cpcolor="#4663E3"
                                />
                                <div className="review-point">8.5</div>
                                </div>
                            </div>
                            </div>
                            <div className="bp-text">
                            <h6>
                                <a href="/#">This gaming laptop with a GTX 1660...</a>
                            </h6>
                            <ul>
                                <li>
                                <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                <i className="fa fa-comment-o" /> 20
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div className="bp-item">
                            <div className="bp-loader">
                            <div className="loader-circle-wrap">
                                <div className="loader-circle">
                                <span
                                    className="circle-progress-1"
                                    data-cpid="id-3"
                                    data-cpvalue={80}
                                    data-cpcolor="#4663E3"
                                />
                                <div className="review-point">8.0</div>
                                </div>
                            </div>
                            </div>
                            <div className="bp-text">
                            <h6>
                                <a href="/#">This gaming laptop with a GTX 1660...</a>
                            </h6>
                            <ul>
                                <li>
                                <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                <i className="fa fa-comment-o" /> 20
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div className="bp-item">
                            <div className="bp-loader">
                            <div className="loader-circle-wrap">
                                <div className="loader-circle">
                                <span
                                    className="circle-progress-1"
                                    data-cpid="id-4"
                                    data-cpvalue={75}
                                    data-cpcolor="#4663E3"
                                />
                                <div className="review-point">7.5</div>
                                </div>
                            </div>
                            </div>
                            <div className="bp-text">
                            <h6>
                                <a href="/#">This gaming laptop with a GTX 1660...</a>
                            </h6>
                            <ul>
                                <li>
                                <i className="fa fa-clock-o" /> Aug 01, 2019
                                </li>
                                <li>
                                <i className="fa fa-comment-o" /> 20
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="subscribe-option">
                        <div className="section-title">
                            <h5>Subscribe</h5>
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod
                            tempor.
                        </p>
                        <form action="/#">
                            <input type="text" placeholder="Name" />
                            <input type="text" placeholder="Email" />
                            <button type="submit">
                            <span>Subscribe</span>
                            </button>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            {/* Instagram Post Section End */}
        
            {/* Js Plugins */}
            </>
        </div>
    );
}