import { Link, useMatch, useResolvedPath} from "react-router-dom"
import $ from 'jquery';
export default function Navbar() {
    const location = window.location.origin
    const logoUrl = window.location.origin+'/img/logo.png'
    const monthName = ["Jan","Feb","Mar","April","May","Jun","July","Aug","Sep","Oct","Nov","Dec"];
    let newDate = new Date()
    let date = newDate.getDate();
    let month = monthName[newDate.getMonth()];
    let year = newDate.getFullYear();

    return(
        <>
            <div id="preloder">
                <div className="loader"></div>
            </div>
            <div className="humberger-menu-overlay" />
            <div className="humberger-menu-wrapper">
                <div className="hw-logo">
                <a href="/#">
                    <img src="img/logo.png" alt="" />
                </a>
                </div>
                <div className="hw-menu mobile-menu">
                <ul>
                    <li>
                    <a href="/">Home</a>
                    </li>
                    <li>
                    <a href="/about">About</a>
                    </li>
                    <li>
                    <a href="/#">
                        Pages <i className="fa fa-angle-down" />
                    </a>
                    <ul className="dropdown">
                        <li>
                        <a href="./categories-list.html">Categories</a>
                        </li>
                        <li>
                        <a href="./categories-grid.html">Categories grid</a>
                        </li>
                        <li>
                        <a href="./typography.html">Typography</a>
                        </li>
                        <li>
                        <a href="./details-post-default.html">Post default</a>
                        </li>
                        <li>
                        <a href="./details-post-gallery.html">Post gallery</a>
                        </li>
                        <li>
                        <a href="./details-post-review.html">Post review</a>
                        </li>
                        <li>
                        <a href="./contact.html">Contact</a>
                        </li>
                    </ul>
                    </li>
                    <li>
                    <a href="/#">
                        Reviews <i className="fa fa-angle-down" />
                    </a>
                    <ul className="dropdown">
                        <li>
                        <a href="/#">Playstation</a>
                        </li>
                        <li>
                        <a href="/#">Windows</a>
                        </li>
                        <li>
                        <a href="/#">Mobile</a>
                        </li>
                        <li>
                        <a href="/#">Xbox</a>
                        </li>
                    </ul>
                    </li>
                    <li>
                    <a href="/#">
                        Windows <i className="fa fa-angle-down" />
                    </a>
                    <ul className="dropdown">
                        <li>
                        <a href="/#">Playstation</a>
                        </li>
                        <li>
                        <a href="/#">Windows</a>
                        </li>
                        <li>
                        <a href="/#">Mobile</a>
                        </li>
                        <li>
                        <a href="/#">Xbox</a>
                        </li>
                    </ul>
                    </li>
                    <li>
                    <a href="/#">
                        Videos <i className="fa fa-angle-down" />
                    </a>
                    <ul className="dropdown">
                        <li>
                        <a href="/#">Playstation</a>
                        </li>
                        <li>
                        <a href="/#">Windows</a>
                        </li>
                        <li>
                        <a href="/#">Mobile</a>
                        </li>
                        <li>
                        <a href="/#">Xbox</a>
                        </li>
                    </ul>
                    </li>
                </ul>
                </div>
                <div id="mobile-menu-wrap" />
                <div className="hw-copyright">
                Copyright © All rights reserved |{" "}
                <i className="fa fa-heart" aria-hidden="true" /> by{" "}
                <a href="/#" target="_blank">
                    EMAS
                </a>
                </div>
                <div className="hw-social">
                <a href="/#">
                    <i className="fa fa-facebook" />
                </a>
                <a href="/#">
                    <i className="fa fa-twitter" />
                </a>
                <a href="/#">
                    <i className="fa fa-youtube-play" />
                </a>
                <a href="/#">
                    <i className="fa fa-instagram" />
                </a>
                </div>
                <div className="hw-insta-media">
                <div className="section-title">
                    <h5>Instagram</h5>
                </div>
                <div className="insta-pic">
                    <img src="img/instagram/ip-1.jpg" alt="" />
                    <img src="img/instagram/ip-2.jpg" alt="" />
                    <img src="img/instagram/ip-3.jpg" alt="" />
                    <img src="img/instagram/ip-4.jpg" alt="" />
                </div>
                </div>
            </div>
            <header className="header-section">
                <div className="ht-options">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-8">
                                <div className="ht-widget">
                                    <ul>
                                        <li id="temprature">
                                            
                                        </li>
                                        <li>
                                            <i className="fa fa-clock-o" /> {date<10?`0${date}`:`${date}`} {month}, {year}
                                        </li>
                                        <li className="signup-switch signup-open">
                                            <i className="fa fa-sign-out" /> Login / Sign up
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-4">
                                <div className="ht-social">
                                    <a href="/#">
                                        <i className="fa fa-facebook" />
                                    </a>
                                    <a href="/#">
                                        <i className="fa fa-twitter" />
                                    </a>
                                    <a href="/#">
                                        <i className="fa fa-youtube-play" />
                                    </a>
                                    <a href="/#">
                                        <i className="fa fa-instagram" />
                                    </a>
                                    <a href="/#">
                                        <i className="fa fa-envelope-o" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="logo">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 spiralbox">
                                2D Animation
                                <div className="spiral" id="spiral1"></div>
                                <div className="spiral" id="spiral2"></div>
                            </div>
                            <div className="col-lg-4 text-center">
                                <a href="/">
                                <img src={logoUrl} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nav-options">
                    <div className="container">
                        <div className="humberger-menu humberger-open">
                            <i className="fa fa-bars" />
                        </div>
                        <div className="nav-search search-switch">
                            <i className="fa fa-search" />
                        </div>
                        <div className="nav-menu">
                            <ul>
                                <CustomLink className="" to="/"><span>Home</span></CustomLink>                        
                                <CustomLink className="" to="/about"><span>About</span></CustomLink>                        
                                <CustomLink className="" to="/services"><span>Services</span></CustomLink>                        
                                <CustomLink className="" to="/projects"><span>Projects</span></CustomLink>                        
                                <CustomLink className="" to="/portfolio"><span>Portfolio</span></CustomLink>                        
                                <CustomLink className="" to="/contact"><span>Contact</span></CustomLink>
                                <li className="mega-menu">
                                    <a href="/services">
                                        <span>
                                        Services <i className="fa fa-angle-down" />
                                        </span>
                                    </a>
                                    <div className="megamenu-wrapper">
                                        <ul className="mw-nav">
                                            <li>
                                                <a href="/#">
                                                <span>3D Animation</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                <span>Voiceover</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                <span>Website Designing</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                <span>App Development</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                <span>Video Editing</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                <span>Social Media Handling</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                <span>Logo Designing</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                <span>UI/UX Design</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                <span>Character Modeling</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                <span>Walkthroughs of Buildings</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                <span>Ads of Product</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                <span>Ads of Product</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                <span>2D Animation</span>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="mw-post">
                                        <div className="mw-post-item">
                                            <div className="mw-pic">
                                            <img src="img/megamenu/mm-1.jpg" alt="" />
                                            </div>
                                            <div className="mw-text">
                                            <h6>
                                                <a href="/#">
                                                A Monster Prom poster got hijacked for a Papa Roach
                                                concert...
                                                </a>
                                            </h6>
                                            <ul>
                                                <li>
                                                <i className="fa fa-clock-o" /> Aug 01, 2019
                                                </li>
                                                <li>
                                                <i className="fa fa-comment-o" /> 12
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        <div className="mw-post-item">
                                            <div className="mw-pic">
                                            <img src="img/megamenu/mm-2.jpg" alt="" />
                                            </div>
                                            <div className="mw-text">
                                            <h6>
                                                <a href="/#">
                                                A new Borderlands 3 trailer introduces Moze and her...
                                                </a>
                                            </h6>
                                            <ul>
                                                <li>
                                                <i className="fa fa-clock-o" /> Aug 01, 2019
                                                </li>
                                                <li>
                                                <i className="fa fa-comment-o" /> 12
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        <div className="mw-post-item">
                                            <div className="mw-pic">
                                            <img src="img/megamenu/mm-3.jpg" alt="" />
                                            </div>
                                            <div className="mw-text">
                                            <h6>
                                                <a href="/#">
                                                Teamfight Tactics is in chaos after today's patch...
                                                </a>
                                            </h6>
                                            <ul>
                                                <li>
                                                <i className="fa fa-clock-o" /> Aug 01, 2019
                                                </li>
                                                <li>
                                                <i className="fa fa-comment-o" /> 12
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        <div className="mw-post-item">
                                            <div className="mw-pic">
                                            <img src="img/megamenu/mm-4.jpg" alt="" />
                                            </div>
                                            <div className="mw-text">
                                            <h6>
                                                <a href="/#">
                                                Borderlands 2 dev explains why there's mysterious
                                                boxes...
                                                </a>
                                            </h6>
                                            <ul>
                                                <li>
                                                <i className="fa fa-clock-o" /> Aug 01, 2019
                                                </li>
                                                <li>
                                                <i className="fa fa-comment-o" /> 12
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        <div className="mw-post-item">
                                            <div className="mw-pic">
                                            <img src="img/megamenu/mm-5.jpg" alt="" />
                                            </div>
                                            <div className="mw-text">
                                            <h6>
                                                <a href="/#">
                                                Capcom asks select fans to test new Resident Evil game
                                                </a>
                                            </h6>
                                            <ul>
                                                <li>
                                                <i className="fa fa-clock-o" /> Aug 01, 2019
                                                </li>
                                                <li>
                                                <i className="fa fa-comment-o" /> 12
                                                </li>
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </li>
                                {/* <li>
                                <a href="/#">
                                    <span>
                                    Pages <i className="fa fa-angle-down" />
                                    </span>
                                </a>
                                <ul className="dropdown">
                                    <li>
                                    <a href="./categories-list.html">Categories</a>
                                    </li>
                                    <li>
                                    <a href="./categories-grid.html">Categories grid</a>
                                    </li>
                                    <li>
                                    <a href="./typography.html">Typography</a>
                                    </li>
                                    <li>
                                    <a href="./details-post-default.html">Post default</a>
                                    </li>
                                    <li>
                                    <a href="./details-post-gallery.html">Post gallery</a>
                                    </li>
                                    <li>
                                    <a href="./details-post-review.html">Post review</a>
                                    </li>
                                    <li>
                                    <a href="./contact.html">Contact</a>
                                    </li>
                                </ul>
                                </li>
                                <li>
                                <a href="/#">
                                    <span>
                                    Reviews <i className="fa fa-angle-down" />
                                    </span>
                                </a>
                                </li>
                                <li>
                                <a href="/#">
                                    <span>
                                    Windows <i className="fa fa-angle-down" />
                                    </span>
                                </a>
                                </li>
                                <li>
                                <a href="/#">
                                    <span>
                                    Videos <i className="fa fa-angle-down" />
                                    </span>
                                </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    return (
      <li className={isActive ? "active" : ""}>
        <Link to={to} {...props}>
          {children}
        </Link>
      </li>
    )
  }

  $(window).on('load', function () {
    $(".loader").fadeOut();
    $("#preloder").delay(200).fadeOut("slow");
});

