import { Link } from "react-router-dom"

export default function Conatct() {
    return(
        <div>
            <>
            {/* Breadcrumb Section Begin */}
            <section
                className="breadcrumb-section set-bg spad"
                style={{backgroundImage:'url("../img/breadcrumb-bg.jpg")'}}
            >
                <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                    <div className="breadcrumb-text">
                        <h3>Contact us</h3>
                        <div className="bt-option">
                        <a href="/">Home</a>
                        <span>Contact</span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            {/* Breadcrumb Section End */}
            {/* Contact Section Begin */}
            <section className="contact-section spad">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="contact-text">
                        <div className="contact-title">
                        <h3>Contact us</h3>
                        <p>
                            My experience with Realy is absolutely positive. The themes are
                            beautifully
                            <br />
                            designed and well documented. Realy theme provides quick
                            support.
                        </p>
                        </div>
                        <div className="contact-form">
                        <div className="dt-leave-comment">
                            <form action="#">
                            <div className="input-list">
                                <input type="text" placeholder="Name" />
                                <input type="text" placeholder="Email" />
                                <input type="text" placeholder="Website" />
                            </div>
                            <textarea placeholder="Message" defaultValue={""} />
                            <button type="submit">Submit</button>
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            {/* Contact Section End */}
            </>

            
        </div>
    );
}