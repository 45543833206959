export default function Footer() {
    return(
        <>
            {/* Footer Section Begin */}
            <footer className="footer-section">
                <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                    <div className="footer-about">
                        <div className="fa-logo">
                        <a href="/#">
                            <img src="img/logo.png" alt="" />
                        </a>
                        </div>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua lacus
                        vel facilisis.
                        </p>
                        <div className="fa-social">
                        <a href="/#">
                            <i className="fa fa-facebook" />
                        </a>
                        <a href="/#">
                            <i className="fa fa-twitter" />
                        </a>
                        <a href="/#">
                            <i className="fa fa-youtube-play" />
                        </a>
                        <a href="/#">
                            <i className="fa fa-instagram" />
                        </a>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                    <div className="editor-choice">
                        <div className="section-title">
                        <h5>Editor's Choice</h5>
                        </div>
                        <div className="ec-item">
                        <div className="ec-pic">
                            <img src="img/trending/editor-1.jpg" alt="" />
                        </div>
                        <div className="ec-text">
                            <h6>
                            <a href="/#">
                                A Monster Prom poster got hijacked for a Papa Roach
                                concert...
                            </a>
                            </h6>
                            <ul>
                            <li>
                                <i className="fa fa-clock-o" /> Aug 01, 2019
                            </li>
                            <li>
                                <i className="fa fa-comment-o" /> 12
                            </li>
                            </ul>
                        </div>
                        </div>
                        <div className="ec-item">
                        <div className="ec-pic">
                            <img src="img/trending/editor-2.jpg" alt="" />
                        </div>
                        <div className="ec-text">
                            <h6>
                            <a href="/#">
                                Facebook wants to read your thoughts with its augmented...
                            </a>
                            </h6>
                            <ul>
                            <li>
                                <i className="fa fa-clock-o" /> Aug 01, 2019
                            </li>
                            <li>
                                <i className="fa fa-comment-o" /> 12
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                    <div className="tags-cloud">
                        <div className="section-title">
                        <h5>Tags Cloud</h5>
                        </div>
                        <div className="tag-list">
                        <a href="/#">
                            <span>Gaming</span>
                        </a>
                        <a href="/#">
                            <span>Platform</span>
                        </a>
                        <a href="/#">
                            <span>Playstation</span>
                        </a>
                        <a href="/#">
                            <span>Hardware</span>
                        </a>
                        <a href="/#">
                            <span>Reviews</span>
                        </a>
                        <a href="/#">
                            <span>Simulation</span>
                        </a>
                        <a href="/#">
                            <span>Strategy</span>
                        </a>
                        <a href="/#">
                            <span>Scientific</span>
                        </a>
                        <a href="/#">
                            <span>References</span>
                        </a>
                        <a href="/#">
                            <span>Role-playing</span>
                        </a>
                        <a href="/#">
                            <span>Adventurea</span>
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="copyright-area">
                    <div className="row">
                    <div className="col-lg-6">
                        <div className="ca-text">
                        <p>
                            Copyright © All rights reserved |{" "}
                            <i className="fa fa-heart" aria-hidden="true" /> by{" "}
                            <a href="/#" target="_blank">
                            EMAS
                            </a>
                        </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ca-links">
                        <a href="/#">About</a>
                        <a href="/#">Subscribe</a>
                        <a href="/#">Contact</a>
                        <a href="/#">Support</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </footer>
            {/* Footer Section End */}
            {/* Sign Up Section Begin */}
            <div className="signup-section">
                <div className="signup-close">
                <i className="fa fa-close" />
                </div>
                <div className="signup-text">
                <div className="container">
                    <div className="signup-title">
                    <h2>Sign up</h2>
                    <p>Fill out the form below to recieve a free and confidential</p>
                    </div>
                    <form action="/#" className="signup-form">
                    <div className="sf-input-list">
                        <input
                        type="text"
                        className="input-value"
                        placeholder="User Name*"
                        />
                        <input type="text" className="input-value" placeholder="Password" />
                        <input
                        type="text"
                        className="input-value"
                        placeholder="Confirm Password"
                        />
                        <input
                        type="text"
                        className="input-value"
                        placeholder="Email Address"
                        />
                        <input
                        type="text"
                        className="input-value"
                        placeholder="Full Name"
                        />
                    </div>
                    <div className="radio-check">
                        <label htmlFor="rc-agree">
                        I agree with the term &amp; conditions
                        <input type="checkbox" id="rc-agree" />
                        <span className="checkbox" />
                        </label>
                    </div>
                    <button type="submit">
                        <span>REGISTER NOW</span>
                    </button>
                    </form>
                </div>
                </div>
            </div>
            {/* Sign Up Section End */}
            {/* Search model Begin */}
            <div className="search-model">
                <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="search-close-switch">+</div>
                <form className="search-model-form">
                    <input type="text" id="search-input" placeholder="Search here....." />
                </form>
                </div>
            </div>
            {/* Search model end */}
        </>
    );
}