import { Link } from "react-router-dom"

export default function Services() {
    return(
        <div>
            <>
            {/* Breadcrumb Section Begin */}
            <section
                className="breadcrumb-section set-bg spad"
                style={{backgroundImage:'url("../img/breadcrumb-bg.jpg")'}}
            >
                <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                    <div className="breadcrumb-text">
                        <h3>Services</h3>
                        <div className="bt-option">
                        <a href="/">Home</a>
                        <span>Services</span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            {/* Breadcrumb Section End */}
            {/* Contact Section Begin */}
            <section className="contact-section spad">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="contact-text">
                        <div className="contact-title">
                        <h3>Services</h3>
                        <p>
                        Welcome to EMAS Design Studio, where creativity knows no bounds. EMAS, an acronym for Eclectic Media And Animation Studio, is your destination for cutting-edge multimedia solutions. Our passion lies in weaving captivating stories through the art of animation and design.
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            {/* Contact Section End */}
            </>

            
        </div>
    );
}